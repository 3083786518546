import React from "react"
import SEO from "../components/seo"

import animated_logo from "../images/logo-animated-lavalamp.svg"

import "./landing.css"

const Landing = () => (
  <section
    style={{
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#f89e46",
      color: "#56110c",
    }}
  >
    <SEO title="Landing" />
    <img
      src={animated_logo}
      style={{ flex: "1 1 auto", maxHeight: "70vh" }}
      alt="Animated Logsicle Logo"
    />
    <h2 style={{ fontSize: "3.5em" }}>Coming Soon...</h2>
  </section>
)

export default Landing
