import React from "react"
import { Link } from "gatsby"

import Image from "../components/image"
import Landing from "../components/landing"

// const IndexPage = () => (
//   <Layout>
//     <SEO title="Home" />
//     <h1>Hi people</h1>
//     <p>Welcome to your new Gatsby site.</p>
//     <p>Now go build something great.</p>
//     <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//       <Image imgPath="gastsby-astronaut.png" />
//     </div>
//     <Link to="/page-2/">Go to page 2</Link>
//   </Layout>
// )

const LandingPage = () => <Landing />

export default LandingPage
